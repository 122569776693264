<template>
  <div class="user" id="user">
    <!--顶部-->
    <header-nav :current="current"></header-nav>
    <div class="content" ref="content">
      <div class="box clearfix">
        <user-left></user-left>
        <div class="user-right" ref="right">
          <div class="title-top"><strong>已购买量表</strong>
            <router-link class="a-fr" to="/form">更多</router-link>
          </div>
          <div class="table-box">
            <table>
              <tr class="a-ta-c">
                <td class="tx-l">测评量表名称</td>
                <td>购买次数</td>
                <td>已用次数</td>
                <td>剩余次数</td>
                <td>操作</td>
              </tr>
              <tr v-for="(item, index) in myQuestion" :key="index">
                <td>
                  <div class="table-name a-e1">{{item.title}}</div>
                </td>
                <td>
                  <div class="table-name a-ta-c">{{item.buy_count}}</div>
                </td>
                <td class="a-ta-c">{{(item.buy_count)-(item.count)}}</td>
                <td class="a-ta-c">
                  <span class="table-number">{{item.count}}</span>
                </td>
                <td>
                  <div class="btn1-box">
                    <p><span
                      @click="startTest(item.title_id)">开始测评</span>
                    </p>
                    <p><span
                      @click="$router.push('/testcenter?type_id=' + item.title_id + '&id=' + item.title_id)">购买量表</span>
                    </p>
                  </div>
                </td>
              </tr>
            </table>
            <div class="no-data icon-nodata" :class="{ hide: !nodata }"></div>
          </div>
          <div class="title-top mar-t60"><strong>我的测评</strong>
            <router-link class="a-fr" to="/inventoryList">更多</router-link>
          </div>
          <div class="table-box">
            <table>
              <tr class="a-ta-c">
                <td class="tx-l">测评量表名称</td>
                <td>
                  <div style="width: 100px;">测评人</div>
                </td>
                <td>
                  <div style="width: 120px;">测评时间</div>
                </td>
                <td>测评状态</td>
                <td>操作</td>
              </tr>
              <tr v-for="item in td_case" :key="item.id">
                <td>
                  <div class="table-name a-e1">{{item.title}}</div>
                </td>
                <td class="a-ta-c">{{item.child_name}}</td>
                <td class="a-ta-c">{{item.create_time}}</td>
                <td>
                  <div style="width: 80px;text-align: center;">
                    <span v-if="item.status == 2">已完成</span>
                    <span v-else>测评中</span>
                  </div>
                </td>
                <td style="width:150px">
                  <div class="btn1-box">
                    <p><span v-if="item.status != 2" @click="seeResult(item)">继续</span></p>
                    <p><span v-if="item.status == 2" @click="seeResult(item)">查看详情</span></p>
                    <p><span v-if="item.status == 2" @click="fnPrint(item)">生成报告</span></p>
<!--                    <p><span v-if="item.status == 2" @click="printExcel(item.title_id,item.exam_id)">EXCEL报表</span></p>-->
                  </div>
                </td>
              </tr>
            </table>
            <div class="no-data icon-nodata" :class="{ hide: !nodata1 }"></div>
          </div>
        </div>
      </div>
    </div>
    <!--底部-->
    <footer-nav></footer-nav>
    <!-- my confirm -->
    <my-confirm ref="myComfirm"></my-confirm>
    <div id="printBox"></div>
  </div>

</template>

<script>
  import HeaderNav from '@/components/HeaderNav'
  import FooterNav from '@/components/FooterNav'
  import UserLeft from '@/components/UserLeft'
  import MyConfirm from '@/components/MyConfirm'

  export default {
    name: 'user',
    data() {
      return {
        current: 'user',
        userinfo: {
          nickname: '',
          avatar: '',
          mobile: ''
        },
        feedback: '',
        td_case: [],
        myQuestion: [],
        nodata: false,
        nodata1: false
      }
    },
    components: {
      HeaderNav,
      FooterNav,
      UserLeft,
      MyConfirm
    },
    methods: {
      getMyQuestion: function () {
        let params = {
          token: localStorage.getItem('token'),
          page: 1,
          limit: 10
        }
        this.$post('question/myQuestion', params).then((res) => {
          // console.log(res)
          if (res.code === 1) {
            if (res.data.totalCount) {
              this.myQuestion = res.data.listData
            } else {
              this.nodata = true
            }
          } else {
            this.$layer.msg(res.msg)
          }
        }).catch((err) => {
          this.$layer.msg('网络异常，请稍后重试123！')
        })
      },
      // 获取历史测评
      getHistory: function () {
        let params = {
          page: 1,
          limit: 5
        }
        this.$post('question/examHistory', params).then((res) => {
          console.log(res)
          if (res.code === 1) {
            if (res.data.totalCount) {
              this.td_case = res.data.listData
            } else {
              this.nodata1 = true
            }
          } else {
            this.$layer.msg(res.msg)
            this.nodata = true
          }
        }).catch((err) => {
          this.$layer.msg('网络异常，请稍后重试456！')
          this.nodata = true
        })
      },
      seeResult: function (item) {
        if (item.position == 'word') {
          this.$router.push('/words?eid=' + item.exam_id + "&id=" + item.title_id)
        } else {
          if (item.status == 2) {
            this.$router.push('/' + item.type + '?eid=' + item.exam_id + "&id=" + item.title_id)
            // this.fnPrint(item)
          } else {
            localStorage.setItem('eid', item.exam_id)
            localStorage.setItem('tid', item.title_id)
            localStorage.setItem('qid', item.position)
            this.$router.push('/test')
          }
        }
      },
      printExcel(titleId, exId) {
        window.location.href = this.GLOBAL.WEB_SITE + '/api/question/examexport.html' + '?token=' + localStorage.getItem('token') + '&title_id=' + titleId + '&ids=' + exId
      },
      startTest: function (id) {
        this.$router.push('/baseinfo?id=' + id)
      },
      submitSuggest: function () {
        if (!this.feedback) {
          this.$layer.msg('请输入意见内容！')
          return true
        }
        let params = {
          feedback: this.feedback
        }
        this.$post('feedback', params).then(res => {
          this.$layer.msg(res.msg)
          if (res.code === 1) {
            this.feedback = ''
          }
        }).catch(response => {
          this.$layer.msg('网络异常，请稍后重试789！')
        })
      },
      fnPrint: function (item) {
        if (!!window.ActiveXObject || "ActiveXObject" in window) {
          let confirm = this.$refs.myComfirm
          confirm.open('提示', {
            content: 'IE浏览器无法使用此功能，请更换浏览器！',
            btn: ['确定']
          }, () => {
            confirm.hide()
          })
          return false
        }
        if (document.getElementById('iframeId')) {
          document.getElementById('iframeId').remove()
        }
        let printBox = document.getElementById('printBox')
        this.$layer.msg('导出中请稍后', {time: 3})
        let url = this.GLOBAL.WEB_SITE + '/#/' + item.type + '?eid=' + item.exam_id + '&id=' + item.title_id + '&print=1'
        // let url = 'localhost:8080/#/' + item.type + '?eid=' + item.exam_id + '&id=' + item.title_id + '&print=1'
        let iframe = document.createElement('iframe')
        iframe.style.cssText = 'width:0;height:0'
        iframe.id = 'iframeId'
        iframe.src = url
        printBox.appendChild(iframe)
      }
    },
    mounted: function () {
      let h = document.documentElement.clientHeight || document.body.clientHeight
      this.$refs.content.style.minHeight = h - 124 + 'px'
      this.$refs.right.style.minHeight = h - 144 + 'px'
    },
    created() {
      this.getMyQuestion()
      this.getHistory()
    }
  }
</script>

<style lang="less" scoped>
  @import url('../assets/css/page/user');

  .btn1-box {
    min-width: 60px;
    text-align: center;
  }

  .btn1-box span {
    color: #f39902;
    padding: 0 5px;
    cursor: pointer;
  }

  .btn1-box span:hover {
    text-decoration: underline;
  }

  .no-data {
    height: 200px;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 120px;
  }
</style>
